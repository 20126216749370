import CloseIcon from '@mui/icons-material/Close'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

import { startEmerGaUrgence, setUserConsent, setEmerGaUrgenceStatus } from '../../store'

import { Modal } from '../../components'

import { urgenceFetchUtils } from '../../utils'

const UrgenceAvertissement = (props) => {
  return (
    <Modal open={props.modal}>
      <div className='flex-h'>
        <div
          className='btn-back'
          onClick={() => {
            props.dispatch(setEmerGaUrgenceStatus(null))
            props.dispatch(startEmerGaUrgence(false))
          }}
        >
          <CloseIcon />
        </div>
        <h2>Attention !</h2>
      </div>
      <div className='separator'></div>
      <p>
        EmerGa Urgence est un <b>dispositif d’urgence</b>.
      </p>
      <div className='separator-10'></div>
      <p>
        Veillez à ne l’utiliser que si vous êtes confronté.e à une situation qui requiert une prise
        en charge d’urgence par des personnels qualifiés.
      </p>
      {props.isEmbed && (
        <>
          <div className='separator-10'></div>
          <p>
            <b>
              Nous allons vous demander d’accepter un certain nombre de conditions pour pouvoir
              utiliser la plateforme.
            </b>
          </p>
        </>
      )}

      <div className='separator'></div>
      <button
        className='btn-next'
        type='button'
        onClick={() => {
          if (!props.isEmbed) {
            props.dispatch(setEmerGaUrgenceStatus('CONSENT'))
          } else {
            props.dispatch(
              setUserConsent({ geolocalisation: true, cgu: true, transmissionInfosSp: true }),
            )
            urgenceFetchUtils.launch(
              props.dispatch,
              true,
              true,
              { geolocalisation: true, cgu: true, transmissionInfosSp: true },
              props.INTEGRAL_STORE,
              props.userId,
            )
          }
        }}
      >
        <span>Continuer</span>
        <ArrowForwardIcon />
      </button>
    </Modal>
  )
}

export { UrgenceAvertissement }
