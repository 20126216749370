const Metadata = {
  teamMail: 'contact@emerga.fr',
  siteUrl: 'https://emerga.fr/',
  appDetailsUrl: 'https://emerga.fr/application',
  appDownloadUrl: 'https://emerga.fr/application',

  firstBilanQuestionsNumber: 5,
}

export { Metadata }
