import { useState, useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
  Fab,
  Skeleton,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  Tooltip,
  Chip,
} from '@mui/material'

import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import CloseIcon from '@mui/icons-material/Close'
import CallIcon from '@mui/icons-material/Call'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import SettingsIcon from '@mui/icons-material/Settings'
import QrCode2Icon from '@mui/icons-material/QrCode2'

import { styled } from '@mui/material/styles'
import { tooltipClasses } from '@mui/material/Tooltip'

import { QrScanner } from '@yudiel/react-qr-scanner'

import {
  startEmerGaUrgence,
  setEmerGaUrgenceStatus,
  setFatalError,
  setCallNeeded,
  setSinapseInstruction,
} from '../store'
import { urgenceFetchUtils } from '../utils'

import { Modal } from '../components'
import {
  UrgenceQuestions,
  UrgenceOnboarding,
  UrgenceAvertissement,
  UrgenceConsent,
  UrgenceClosed,
} from './UrgenceScreens'
import Splash from './Splash'

import LogoEu from '../assets/logo-app.png'
import { useParams } from 'react-router-dom'

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}, & .${tooltipClasses.arrow}::before`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white.main,
    boxShadow: theme.shadows[1],
    fontSize: 13,
    padding: '8px 12px',
    fontWeight: 400,
    lineHeight: 1.15,
  },
  [`& .${tooltipClasses.arrow}::before`]: {
    padding: 0,
  },
}))

const Urgence = ({ socket }) => {
  const [isReadyForDisplay, setReadyForDisplay] = useState(false)
  const serverConfig = useSelector((Store) => Store.Webapp.serverConfig)
  const [userId, setUserId] = useState(null)
  const [updateConsentModal, setUpdateConsentModal] = useState(false)

  const dispatch = useDispatch()
  const params = useParams()

  useEffect(() => {
    if (params.mtId) {
      urgenceFetchUtils.retrieveUrgence(
        params.mtId,
        dispatch,
        serverConfig,
        setReadyForDisplay,
        setUpdateConsentModal,
      )
    } else if (params.userId) {
      setUserId(params.userId)
      setReadyForDisplay(true)
    } else {
      setReadyForDisplay(true)
    }
  }, [params.mtId, params.userId])

  const [loadedLogoEu, setLoadedLogoEu] = useState(false)
  const [callModal, setCallModal] = useState(false)
  const [closeModal, setCloseModal] = useState(false)
  const [popperOpened, changePopperStatus] = useState(false)
  const [lastPopupStatus, setLastPopupStatus] = useState(false)
  const [askForQr, setAskForQr] = useState(false)

  const userConsent = useSelector((Store) => Store.Webapp.userConsent)
  const isEmerGaUrgenceRunning = useSelector((Store) => Store.Webapp.isEmerGaUrgenceRunning)
  const emergaUrgenceStatus = useSelector((Store) => Store.Webapp.EmerGaUrgenceStatus)
  const fatalError = useSelector((Store) => Store.Webapp.fatalError)
  const urgenceData = useSelector((Store) => Store.Webapp.urgenceData)
  const jwt = useSelector((Store) => Store.Webapp.urgenceJWT)
  const emergencyCallNeeded = useSelector((Store) => Store.Webapp.emergencyCallNeeded)
  const sinapseInstruction = useSelector((Store) => Store.Webapp.sinapseInstruction)

  const defaultStore = useSelector((Store) => Store.Webapp)

  var INTEGRAL_STORE = {
    isRunning: defaultStore.isRunning,
    isMobile: defaultStore.isMobile,
    serverConfig: {
      ...defaultStore.serverConfig,
    },
    alerts: { ...defaultStore.alerts },
    urgenceMode: defaultStore.urgenceMode,
    isEmerGaUrgenceRunning: defaultStore.isEmerGaUrgenceRunning,
    EmerGaUrgenceStatus: defaultStore.EmerGaUrgenceStatus,
    userConsent: { ...defaultStore.userConsent },
    urgenceJWT: defaultStore.urgenceJWT,
    urgenceData: {
      ...defaultStore.urgenceData,
    },
    fatalError: { ...defaultStore.fatalError },
    geolocationData: { ...defaultStore.geolocationData },
    emergencyCallNeeded: defaultStore.emergencyCallNeeded,
  }
  if (!['CLOSED', 'AVERTISSEMENT', 'CONSENT'].includes(emergaUrgenceStatus)) {
    sessionStorage.setItem('store', JSON.stringify(INTEGRAL_STORE))
  }

  const sliderRef = useRef()

  return isReadyForDisplay ? (
    <>
      {!userId && (
        <>
          <div className='urgence__header'>
            {isEmerGaUrgenceRunning ? (
              <SettingsIcon
                className='urgence__header-parameters'
                sx={{ fontSize: 30 }}
                onClick={() => {
                  setUpdateConsentModal(true)
                }}
              />
            ) : null}

            <div className='urgence__header_logoContainer'>
              {!loadedLogoEu ? (
                <Skeleton variant='rectangular' width='50%' height={75} animation='wave' />
              ) : null}
              <img
                className='urgence__header_logo'
                src={LogoEu}
                alt='EmerGa Urgence'
                onLoad={() => {
                  setLoadedLogoEu(true)
                }}
                style={{ display: loadedLogoEu ? 'block' : 'none' }}
              />
            </div>
          </div>
          <div className='urgence__header-mask'></div>
        </>
      )}

      <div className={!userId ? 'mainRow withHeader' : 'mainRow embed'}>
        {!isEmerGaUrgenceRunning && emergaUrgenceStatus === 'CLOSED' ? (
          <UrgenceClosed dispatch={dispatch} userId={userId} />
        ) : !isEmerGaUrgenceRunning ? (
          <UrgenceOnboarding isEmbed={params.userId ? true : false} dispatch={dispatch} />
        ) : emergaUrgenceStatus === 'LAUNCH_PENDING' ? (
          <UrgenceQuestions pending={true} />
        ) : emergaUrgenceStatus === 'AVERTISSEMENT' ? (
          <>
            <UrgenceAvertissement
              modal={emergaUrgenceStatus === 'AVERTISSEMENT'}
              dispatch={dispatch}
              isEmbed={params.userId ? true : false}
              INTEGRAL_STORE={INTEGRAL_STORE}
              userId={userId}
            />
            <UrgenceOnboarding dispatch={dispatch} />
          </>
        ) : emergaUrgenceStatus === 'CONSENT' ? (
          <>
            <UrgenceConsent
              modal={emergaUrgenceStatus === 'CONSENT'}
              dispatch={dispatch}
              kind='initial'
              INTEGRAL_STORE={INTEGRAL_STORE}
              userId={userId}
            />
            <UrgenceOnboarding dispatch={dispatch} />
          </>
        ) : emergaUrgenceStatus === 'QUESTIONING' ? (
          <CustomTooltip
            title={
              <>
                Pour commencer, nous allons vous poser quelques questions.
                <br />
                Gardez votre calme, <b>nous allons vous aider</b>.
              </>
            }
            open={popperOpened}
          >
            <UrgenceQuestions
              INTEGRAL_STORE={INTEGRAL_STORE}
              questioning={true}
              urgenceData={urgenceData}
              jwt={jwt}
              dispatch={dispatch}
              changePopperStatus={changePopperStatus}
              lastPopupStatus={lastPopupStatus}
              setLastPopupStatus={setLastPopupStatus}
            />
          </CustomTooltip>
        ) : emergaUrgenceStatus === 'ADVANCED_BILAN' ? (
          <CustomTooltip
            title={
              <>
                <b>Très bien.</b> Nous allons vous poser des questions complémentaires pour vous
                aider à réagir.
              </>
            }
            open={popperOpened}
          >
            <UrgenceQuestions
              INTEGRAL_STORE={INTEGRAL_STORE}
              advancedBilan={true}
              urgenceData={urgenceData}
              jwt={jwt}
              dispatch={dispatch}
              serverConfig={serverConfig}
              changePopperStatus={changePopperStatus}
              lastPopupStatus={lastPopupStatus}
              setLastPopupStatus={setLastPopupStatus}
            />
          </CustomTooltip>
        ) : emergaUrgenceStatus === 'DISPLAY_GESTURES' ? (
          <CustomTooltip
            title={
              <>
                Merci ! Notre algorithme a pu déterminer face à quel incident vous vous trouvez.{' '}
                <br />
                <b>Nous allons vous aider à porter secours.</b>
              </>
            }
            open={popperOpened}
          >
            <UrgenceQuestions
              INTEGRAL_STORE={INTEGRAL_STORE}
              displayGestures={true}
              urgenceData={urgenceData}
              jwt={jwt}
              dispatch={dispatch}
              sliderRef={sliderRef}
              setCallModal={setCallModal}
              setCloseModal={setCloseModal}
              changePopperStatus={changePopperStatus}
              lastPopupStatus={lastPopupStatus}
              setLastPopupStatus={setLastPopupStatus}
            />
          </CustomTooltip>
        ) : null}

        {
          //FAB

          isEmerGaUrgenceRunning ? (
            <SpeedDial
              ariaLabel="Plus d'options"
              sx={{
                position: 'absolute',
                bottom: 20,
                right: 20,
              }}
              icon={<SpeedDialIcon openIcon={<CloseIcon />} />}
            >
              <SpeedDialAction
                icon={<CallIcon />}
                tooltipTitle='Joindre les secours'
                FabProps={{ color: 'red', className: 'callFab' }}
                onClick={() => {
                  setCallModal(true)
                }}
              />
              {/*<SpeedDialAction
                                icon={<ToggleOnIcon />}
                                tooltipTitle="Modifier mes paramètres de consentement"
                                
                            />*/}
              <SpeedDialAction
                icon={<QrCode2Icon />}
                tooltipTitle='Scanner un identifiant'
                FabProps={{ color: 'blue', className: 'scanFab' }}
                onClick={() => {
                  setAskForQr(true)
                }}
              />
              <SpeedDialAction
                icon={<DeleteForeverIcon />}
                tooltipTitle="Clôturer l'incident"
                FabProps={{ color: 'red', className: 'closeFab' }}
                onClick={() => {
                  setCloseModal(true)
                }}
              />
            </SpeedDial>
          ) : (
            <>
              <Fab
                color='red'
                aria-label='call'
                style={{
                  position: 'absolute',
                  bottom: 20,
                  right: 20,
                }}
                onClick={() => {
                  setCallModal(true)
                }}
              >
                <CallIcon color='white' />
              </Fab>
            </>
          )
        }

        {/* MODAL CALL URGENCE */}
        <Modal open={callModal}>
          <div className='flex-h'>
            <div
              className='btn-back'
              onClick={() => {
                setCallModal(false)
              }}
            >
              <CloseIcon />
            </div>
            <h2>Contacter les secours</h2>
          </div>
          <div className='separator'></div>
          {isEmerGaUrgenceRunning ? (
            <>
              <p>
                Contactez les secours compétents pour vous aider à faire face à l'incident auquel
                vous êtes confronté(e).
              </p>
            </>
          ) : (
            <>
              <p>
                Vous pouvez joindre les services de secours compétents à tout moment, depuis votre
                téléphone.
              </p>
              <div className='separator-10'></div>
              <p>
                Utilisez EmerGa Urgence en complément pour porter secours et transmettre aux
                secouristes les informations utiles.
              </p>
            </>
          )}

          <div className='separator'></div>

          {!serverConfig.isEmerGaAvailable ? (
            <>
              <a
                className='onboarding-button-blue launch-button'
                type='button'
                href='tel:15'
                target='_blank'
                rel='noreferrer'
              >
                <CallIcon />
                <span>Appeler le SAMU (15)</span>
              </a>
              <a
                className='onboarding-button launch-button'
                type='button'
                href='tel:18'
                target='_blank'
                rel='noreferrer'
              >
                <CallIcon />
                <span>Appeler les sapeurs-pompiers (18)</span>
              </a>
            </>
          ) : (
            <>
              {serverConfig.isEmerGaAvailable.map((service, index) => {
                return (
                  <a
                    className={
                      service.competence < 3
                        ? 'onboarding-button-blue launch-button'
                        : 'onboarding-button launch-button'
                    }
                    type='button'
                    href={'tel:' + service.public_phone}
                    target='_blank'
                    rel='noreferrer'
                    key={index}
                  >
                    <CallIcon />
                    <span>
                      {service.public_name} ({service.public_phone})
                    </span>
                  </a>
                )
              })}
            </>
          )}

          {!isEmerGaUrgenceRunning ? (
            <>
              <div className='separator'></div>
              <button
                className='btn-next'
                type='button'
                onClick={() => {
                  setCallModal(false)
                  dispatch(startEmerGaUrgence(true))
                  dispatch(setEmerGaUrgenceStatus('AVERTISSEMENT'))
                }}
              >
                <span>Utiliser EmerGa Urgence</span>
                <ArrowForwardIcon />
              </button>
            </>
          ) : (
            <></>
          )}
        </Modal>

        {/* MODAL CALL URGENCE EN CAS DE DIAGNOSTIC */}
        <Modal open={emergencyCallNeeded}>
          <div className='flex-h'>
            <div
              className='btn-back'
              onClick={() => {
                dispatch(setCallNeeded(false))
              }}
            >
              <CloseIcon />
            </div>
            <h2>Il est nécessaire de joindre les secours !</h2>
          </div>
          <div className='separator'></div>
          <p>
            Vous semblez confronté(e) à un incident nécessitant une intervention rapide des services
            de secours !
          </p>
          <div className='separator-10'></div>
          <p>
            <b>Veuillez joindre les secours recommandés par téléphone.</b>
            <br />
            Les informations récupérées leur seront automatiquement transmises si vous y avez
            consenti.
          </p>
          <div className='separator-10'></div>
          <p>
            Pendant ce temps, EmerGa Urgence va vous présenter les gestes de premiers secours à
            pratiquer.
          </p>

          <div className='separator'></div>

          {urgenceData.competentServices.id ? (
            <a
              className={
                urgenceData.competentServices.competence < 3
                  ? 'onboarding-button-blue launch-button'
                  : 'onboarding-button launch-button'
              }
              type='button'
              href={'tel:' + urgenceData.competentServices.public_phone}
              target='_blank'
              rel='noreferrer'
            >
              <CallIcon />
              <span>
                {urgenceData.competentServices.public_name} (
                {urgenceData.competentServices.public_phone})
              </span>
            </a>
          ) : !serverConfig.isEmerGaAvailable ? (
            <>
              <a
                className='onboarding-button-blue launch-button'
                type='button'
                href='tel:15'
                target='_blank'
                rel='noreferrer'
              >
                <CallIcon />
                <span>Appeler le SAMU (15)</span>
              </a>
              <a
                className='onboarding-button launch-button'
                type='button'
                href='tel:18'
                target='_blank'
                rel='noreferrer'
              >
                <CallIcon />
                <span>Appeler les sapeurs-pompiers (18)</span>
              </a>
            </>
          ) : (
            <>
              {serverConfig.isEmerGaAvailable.map((service, index) => {
                return (
                  <a
                    className={
                      service.competence < 3
                        ? 'onboarding-button-blue launch-button'
                        : 'onboarding-button launch-button'
                    }
                    type='button'
                    href={'tel:' + service.public_phone}
                    target='_blank'
                    rel='noreferrer'
                    key={index}
                  >
                    <CallIcon />
                    <span>
                      {service.public_name} ({service.public_phone})
                    </span>
                  </a>
                )
              })}
            </>
          )}

          {!isEmerGaUrgenceRunning ? (
            <>
              <div className='separator'></div>
              <button
                className='btn-next'
                type='button'
                onClick={() => {
                  setCallModal(false)
                  dispatch(startEmerGaUrgence(true))
                  dispatch(setEmerGaUrgenceStatus('AVERTISSEMENT'))
                }}
              >
                <span>Utiliser EmerGa Urgence</span>
                <ArrowForwardIcon />
              </button>
            </>
          ) : (
            <></>
          )}
        </Modal>

        {/* MODAL FATAL ERROR */}
        <Modal open={fatalError.active}>
          <div className='flex-h'>
            <div
              className='btn-back'
              onClick={() => {
                dispatch(
                  setFatalError({
                    active: false,
                    message:
                      'Une erreur est survenue et empêche notre algorithme de fonctionner correctement.',
                  }),
                )
                dispatch(startEmerGaUrgence(false))
              }}
            >
              <CloseIcon />
            </div>
            <h2>Une erreur est survenue !</h2>
          </div>
          <div className='separator'></div>
          <p>{fatalError.message}</p>
          <div className='separator-10'></div>
          <p>
            Vous pouvez tenter de relancer EmerGa Urgence ou de joindre les secours directement.
          </p>

          <div className='separator'></div>

          {!serverConfig.isEmerGaAvailable ? (
            <>
              <a
                className='onboarding-button-blue launch-button'
                type='button'
                href='tel:15'
                target='_blank'
                rel='noreferrer'
              >
                <CallIcon />
                <span>Appeler le SAMU (15)</span>
              </a>
              <a
                className='onboarding-button launch-button'
                type='button'
                href='tel:18'
                target='_blank'
                rel='noreferrer'
              >
                <CallIcon />
                <span>Appeler les sapeurs-pompiers (18)</span>
              </a>
            </>
          ) : (
            <>
              {serverConfig.isEmerGaAvailable.map((service, index) => {
                return (
                  <a
                    className={
                      service.competence < 3
                        ? 'onboarding-button-blue launch-button'
                        : 'onboarding-button launch-button'
                    }
                    type='button'
                    href={'tel:' + service.public_phone}
                    target='_blank'
                    rel='noreferrer'
                    key={index}
                  >
                    <CallIcon />
                    <span>
                      {service.public_name} ({service.public_phone})
                    </span>
                  </a>
                )
              })}
            </>
          )}
        </Modal>

        {/* MODAL CLÔTURE */}
        <Modal open={closeModal}>
          <div className='flex-h'>
            <div
              className='btn-back'
              onClick={() => {
                setCloseModal(false)
              }}
            >
              <CloseIcon />
            </div>
            <h2>Êtes-vous sûr(e) de vouloir clôturer l'incident ?</h2>
          </div>
          <div className='separator'></div>
          <p>
            Un incident clôturé <b>ne peut pas être rouvert</b>.
          </p>
          <div className='separator-10'></div>
          <p>
            Vous devrez alors saisir de nouveau les informations relatives à l'incident dans la
            plateforme.
          </p>
          <div className='separator-10'></div>
          <p>
            <b>
              Ne clôturez l'incident que si la situation d'urgence est résolue ou si les secours
              interviennent.
            </b>
          </p>

          <div className='separator'></div>

          <button
            className='btn-next'
            type='button'
            onClick={() => {
              urgenceFetchUtils.close(dispatch, jwt).then(() => {
                setCloseModal(false)
              })
            }}
          >
            <span>Clôturer</span>
            <ArrowForwardIcon />
          </button>
        </Modal>

        {/* MODAL CONSENTEMENT */}
        <UrgenceConsent
          isFromSinapse={params.mtId ? true : false}
          modal={updateConsentModal}
          dispatch={dispatch}
          kind='update'
          closeModal={() => {
            setUpdateConsentModal(false)
          }}
          jwt={jwt}
          userConsent={userConsent}
          INTEGRAL_STORE={INTEGRAL_STORE}
        />

        {/* MODAL INSTRUCTION */}
        <Modal open={sinapseInstruction.active}>
          <div className='flex-h'>
            <div
              className='btn-back'
              onClick={() => {
                dispatch(setSinapseInstruction())
              }}
            >
              <CloseIcon />
            </div>
            <h2>Les secours vous envoient un message</h2>
          </div>
          <div className='separator'></div>
          <small style={{ color: '#161667', fontWeight: 500, fontStyle: 'italic' }}>
            {sinapseInstruction.data.author.publicName} :
          </small>

          <div className='separator-10'></div>
          <p style={{ fontSize: '20px', color: 'black' }}>"{sinapseInstruction.data.content}"</p>

          <div className='separator'></div>
          <div className='separator'></div>

          <p>En cas d'aggravement de la situation, contactez les secours.</p>
          {!serverConfig.isEmerGaAvailable ? (
            <>
              <a
                className='onboarding-button-blue launch-button'
                type='button'
                href='tel:15'
                target='_blank'
                rel='noreferrer'
              >
                <CallIcon />
                <span>Appeler le SAMU (15)</span>
              </a>
              <a
                className='onboarding-button launch-button'
                type='button'
                href='tel:18'
                target='_blank'
                rel='noreferrer'
              >
                <CallIcon />
                <span>Appeler les sapeurs-pompiers (18)</span>
              </a>
            </>
          ) : (
            <>
              {serverConfig.isEmerGaAvailable.map((service, index) => {
                return (
                  <a
                    className={
                      service.competence < 3
                        ? 'onboarding-button-blue launch-button'
                        : 'onboarding-button launch-button'
                    }
                    type='button'
                    href={'tel:' + service.public_phone}
                    target='_blank'
                    rel='noreferrer'
                    key={index}
                  >
                    <CallIcon />
                    <span>
                      {service.public_name} ({service.public_phone})
                    </span>
                  </a>
                )
              })}
            </>
          )}
        </Modal>

        {askForQr && (
          <>
            <QrScanner
              onError={(err) => {
                console.log(err)
              }}
              onResult={(data) => {
                if (data !== null) {
                  let m = data.text.match(/^https:\/\/app\.emerga\.fr\/v\/(.*)$/)
                  if (m) {
                    if (m.length === 2) {
                      urgenceFetchUtils.sendVictimId(m[1], dispatch, jwt)
                      setAskForQr(false)
                    }
                  }
                }
              }}
              constraints={{
                facingMode: 'environment',
              }}
              containerStyle={{
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 5000,
                height: '100dvh',
                width: '100vw',
                objectFit: 'cover',
                backgroundColor: 'black',
              }}
            />
            <Chip
              sx={{
                position: 'absolute',
                bottom: '50px',
                left: '50%',
                transform: 'translateX(-50%)',
                zIndex: 6000,
                backgroundColor: '#161667',
                color: '#fff',
              }}
              label='Scannez un QR Code EmerGa.'
            />
            <CloseIcon
              sx={{
                fontSize: 35,
                color: '#fff',
                position: 'absolute',
                top: '20px',
                right: '20px',
                zIndex: 6000,
              }}
              onClick={() => {
                setAskForQr(false)
              }}
            />
          </>
        )}
      </div>
    </>
  ) : (
    <>
      <Splash dontDisplayAlerts={true} />
    </>
  )
}

export default Urgence
