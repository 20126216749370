import { Link } from 'react-router-dom'

import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import HomeIcon from '@mui/icons-material/Home'
import SystemUpdateIcon from '@mui/icons-material/SystemUpdate'

import ClosedIllustration from '../../assets/closed.png'

import { setUrgenceMode } from '../../store'

import { Metadata, PageLinks } from '../../utils'

const UrgenceClosed = (props) => {
  return (
    <div className='urgence-mainRow'>
      <h3>Bravo ! 🎉</h3>
      <p>Vous venez de porter secours. Merci pour cet acte citoyen !</p>
      {!props.userId && (
        <p>Téléchargez l’application EmerGa pour avoir accès à EmerGa Urgence à tout moment.</p>
      )}

      <div className='separator'></div>

      <img className='full-size' src={ClosedIllustration} alt='illustration' />

      <div className='separator'></div>

      {!props.userId && (
        <div className='question-action-main'>
          <a
            className='download-temp-button'
            href={Metadata.appDownloadUrl}
            target='_blank'
            rel='noreferrer'
          >
            <span>Télécharger l'application</span>
            <SystemUpdateIcon color='white' />
          </a>
          <a
            className='question-temp-button'
            href={Metadata.appDetailsUrl}
            target='_blank'
            rel='noreferrer'
          >
            <span>En savoir plus</span>
            <ExitToAppIcon color='black' />
          </a>

          <Link
            to={PageLinks.Onboarding}
            onClick={() => {
              props.dispatch(setUrgenceMode(false))
            }}
          >
            <a className='question-temp-button' href={PageLinks}>
              <span>Revenir à l'accueil</span>
              <HomeIcon color='black' />
            </a>
          </Link>
        </div>
      )}
    </div>
  )
}

export { UrgenceClosed }
