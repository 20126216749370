import { useEffect } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import parse from 'html-react-parser'

import { Snackbar, IconButton, Alert } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { removeAlert, setSinapseInstruction } from './store'

import { Splash, Onboarding, OnboardingFail, Urgence } from './pages'
import { PageLinks, WSEndpoints } from './utils'

import STYLE from './style/main.css'

import io from 'socket.io-client'

const socket = io(WSEndpoints.root, {
    rejectUnauthorized: false,
    transports: ['websockets', 'polling'],
})

function App() {
    const isRunning = useSelector((Store) => Store.Webapp.isRunning)
    const isMobile = useSelector((Store) => Store.Webapp.isMobile)
    const urgenceStarted = useSelector((Store) => Store.Webapp.urgenceMode)
    const urgenceJWT = useSelector((Store) => Store.Webapp.urgenceJWT)
    const alerte = useSelector((Store) => Store.Webapp.alerts)

    const dispatch = useDispatch()

    useEffect(() => {
        socket.connect();
        if (urgenceJWT) {
            console.log('Connecté avec JWT', urgenceJWT)
            socket.emit(
                'APP_HANDSHAKE',
                {
                    jwt: urgenceJWT,
                    type: 1,
                },
                (response) => {
                    console.log(response)
                },
            )
        }

        socket.on('connect_error', () => {
            // revert to classic upgrade
            socket.io.opts.transports = ['polling', 'websocket']
        })

        socket.on('SINAPSE_INSTRUCTION', (data) => {
            dispatch(setSinapseInstruction(data))
        })
        /*
    }, [])

    useEffect(() => {
        if (urgenceJWT) {
            socket.emit("APP_HANDSHAKE", {
                jwt: urgenceJWT,
                type: 1
            }, (response) => {
                console.log(response)
            });
        }*/
    }, [urgenceJWT])

    return (
        <>
            {
                <Routes>
                    <Route
                        path={PageLinks.Accueil}
                        element={!isRunning ? <Splash /> : <Navigate replace to={PageLinks.Onboarding} />}
                    />
                    <Route
                        path={PageLinks.Onboarding}
                        element={
                            !isRunning ? (
                                <Splash />
                            ) : !urgenceStarted ? (
                                isMobile ? (
                                    <Onboarding />
                                ) : (
                                    <OnboardingFail />
                                )
                            ) : (
                                <Navigate replace to={PageLinks.Urgence} />
                            )
                        }
                    />
                    <Route
                        path={PageLinks.Urgence}
                        element={
                            !isRunning ? (
                                <Splash />
                            ) : !urgenceStarted ? (
                                <Navigate replace to={PageLinks.Onboarding} />
                            ) : (
                                <Urgence socket={socket} />
                            )
                        }
                    />
                    <Route
                        path={PageLinks.UrgenceCustom}
                        element={!isRunning ? <Splash dontDisplayAlerts={true} /> : <Urgence socket={socket} />}
                    />
                    <Route
                        path={PageLinks.UrgenceEmbed}
                        element={
                            !isRunning ? (
                                <Splash dontDisplayAlerts={true} simpleDiv={true} />
                            ) : (
                                <Urgence socket={socket} />
                            )
                        }
                    />
                </Routes>
            }

            {alerte.message ? (
                <Snackbar
                    open={alerte.message ? true : false}
                    autoHideDuration={10000}
                    onClose={() => {
                        dispatch(removeAlert())
                    }}
                    sx={{ zIndex: 5005 }}
                >
                    <Alert
                        severity={alerte.kind}
                        sx={alerte.sx}
                        action={
                            <IconButton
                                aria-label='close'
                                color='inherit'
                                size='small'
                                onClick={() => {
                                    dispatch(removeAlert())
                                }}
                            >
                                <CloseIcon fontSize='inherit' />
                            </IconButton>
                        }
                        color={alerte.color}
                        variant={alerte.variant}
                    >
                        {parse(alerte.message)}
                    </Alert>
                </Snackbar>
            ) : null}
        </>
    )
}

export default App
