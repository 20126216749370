import Backdrop from '@mui/material/Backdrop'

const Modal = (props) => {
  return (
    <Backdrop open={props.open} sx={{ zIndex: 4999 }}>
      <div className='modal-container'>
        <div className='modal'>{props.children}</div>
      </div>
    </Backdrop>
  )
}

export default Modal
