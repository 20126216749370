import { configureStore } from '@reduxjs/toolkit'
import WebappReducer from './WebappReducer'
export {
  run,
  setMobile,
  setServerConfig,
  setAlert,
  removeAlert,
  setUrgenceMode,
  startEmerGaUrgence,
  setEmerGaUrgenceStatus,
  setUserConsent,
  setUrgenceJWT,
  setUrgenceQuestionData,
  setFatalError,
  incrementQuestionNumber,
  resetQuestionNumber,
  setGestures,
  setCompetentServices,
  updateGeolocationData,
  setCallNeeded,
  retrieveLastState,
  setSinapseInstruction,
} from './WebappReducer'

const Store = configureStore({
  reducer: {
    Webapp: WebappReducer,
  },
})

/*
const mapDispatchToProps = () => ({
    run, setMobile, setServerConfig, setError, removeError, setUrgenceStarted
});

const mapStateToProps = (state) => ({
    isRunning: state.isRunning,
    isMobile: state.isMobile,
    serverConfig: state.serverConfig,
    error: state.error,
    urgenceStarted: state.urgenceStarted
});*/

export default Store
