import axios from 'axios'
import {
    startEmerGaUrgence,
    setEmerGaUrgenceStatus,
    setAlert,
    setUrgenceJWT,
    setUrgenceQuestionData,
    incrementQuestionNumber,
    resetQuestionNumber,
    setFatalError,
    setGestures,
    setCompetentServices,
    updateGeolocationData,
    setCallNeeded,
} from '../store'
import { APIEndpoints } from './APIEndpoints'
import { Metadata } from './Metadata'

const urgenceFetchUtils = {
    launch: async (
        dispatch,
        geolocation,
        shareInformations,
        userConsent,
        INTEGRAL_STORE,
        userId = null,
    ) => {
        var headers = userId
            ? {
                Authorization: 'Bearer ' + userId,
            }
            : {}
        //Pertinent de reset ici ?
        dispatch(resetQuestionNumber())
        dispatch(setEmerGaUrgenceStatus('LAUNCH_PENDING'))
        axios
            .post(
                APIEndpoints.root + APIEndpoints.launchUrgence,
                {
                    origin: userId ? 'APP' : 'WEBAPP',
                    consent_cgu: true,
                    consent_geolocation: geolocation,
                    consent_share_informations: shareInformations,
                },
                {
                    headers,
                },
            )
            .then((response) => {
                dispatch(
                    setAlert({
                        type: 'success',
                        message: response.data.message,
                    }),
                )
                if (response.status === 200) {
                    dispatch(setUrgenceJWT(response.data.jwt))
                    dispatch(
                        setUrgenceQuestionData({
                            ...response.data.question,
                            answers: response.data.answers,
                        }),
                    )
                    dispatch(incrementQuestionNumber())
                    dispatch(setEmerGaUrgenceStatus('QUESTIONING'))

                    let d = new Date()
                    sessionStorage.setItem('jwt', response.data.jwt)
                    sessionStorage.setItem('time', d.getTime() + 3600000)

                    if (userConsent.geolocalisation) {
                        urgenceFetchUtils.sendGeolocation(dispatch, response.data.jwt)
                    }
                } else {
                    if (response.data.message) {
                        dispatch(setFatalError({ active: true, message: response.data.message }))
                    } else {
                        dispatch(setFatalError({ active: true }))
                    }
                }
            })
            .catch((error) => {
                dispatch(setFatalError({ active: true }))
            })
    },
    checkLaunchable: (userConsent, success, failure) => {
        if (userConsent.cgu) {
            success()
        } else {
            failure()
        }
    },
    handleAnswer: async (
        questionId,
        answerId,
        dispatch,
        jwt,
        questionNumber,
        serverConfig,
        INTEGRAL_STORE,
    ) => {
        dispatch(setEmerGaUrgenceStatus('LAUNCH_PENDING'))
        axios
            .post(
                APIEndpoints.root + APIEndpoints.sendAnswer,
                {
                    //axios bearer token
                    questionId: questionId,
                    answerId: answerId,
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + jwt,
                    },
                },
            )
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.status) {
                        if (response.data.status === 'DISPLAY_GESTURES') {
                            //si suffisamment urgent, on recommande un appel secours
                            dispatch(setGestures({
                                gestures: response.data.gestures,
                                surveillance: response.data.surveillance
                            }))
                            if (response.data.competentServices) {
                                dispatch(setCompetentServices(response.data.competentServices))
                            } else if (serverConfig.isEmerGaAvailable) {
                                dispatch(setCompetentServices(serverConfig.isEmerGaAvailable))
                            } else {
                                dispatch(setCompetentServices([]))
                            }
                            dispatch(setCallNeeded(response.data.emergencyCallNeeded))
                            dispatch(setEmerGaUrgenceStatus('DISPLAY_GESTURES'))

                            return true
                        }
                    }

                    if (response.data.question.type == "DIAGNOSTIC") {
                        dispatch(
                            setUrgenceQuestionData({
                                ...response.data.question,
                                answers: response.data.answers,
                            }),
                        )
                        dispatch(incrementQuestionNumber())
                        dispatch(setEmerGaUrgenceStatus('ADVANCED_BILAN'))

                        return true
                    }

                    dispatch(
                        setUrgenceQuestionData({
                            ...response.data.question,
                            answers: response.data.answers,
                        }),
                    )
                    dispatch(incrementQuestionNumber())
                    dispatch(setEmerGaUrgenceStatus('QUESTIONING'))

                    return true
                } else {
                    if (response.data.message) {
                        dispatch(setFatalError({ active: true, message: response.data.message }))
                    } else if (response.response.data.message) {
                        dispatch(setFatalError({ active: true, message: response.data.message }))
                    } else {
                        dispatch(setFatalError({ active: true }))
                    }
                }
            })
            .catch((error) => {
                if (error.response) {
                    dispatch(setFatalError({ active: true, message: error.response.data.message }))
                } else {
                    dispatch(setFatalError({ active: true }))
                }
            })
    },
    handleGesture: async (gestureId, status, dispatch, jwt, INTEGRAL_STORE) => {
        axios
            .post(
                APIEndpoints.root + APIEndpoints.saveUrgence,
                {
                    //axios bearer token
                    gestureId: gestureId,
                    gestureStatus: status,
                    action: 'RESCUE_GESTURE',
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + jwt,
                    },
                },
            )
            .then((response) => {
                if (response.status !== 200) {
                    if (response.data.message) {
                        dispatch(setFatalError({ active: true, message: response.data.message }))
                    } else if (response.response.data.message) {
                        dispatch(setFatalError({ active: true, message: response.data.message }))
                    } else {
                        dispatch(setFatalError({ active: true }))
                    }
                }

                return true
            })
            .catch((error) => {
                if (error.response) {
                    dispatch(setFatalError({ active: true, message: error.response.data.message }))
                } else {
                    dispatch(setFatalError({ active: true }))
                }
                return false
            })
    },
    updateConsent: async (item, consent, dispatch, jwt, INTEGRAL_STORE) => {
        let b = { consent_cgu: true }
        if (item === 'consent_geolocation') {
            b = { consent_geolocation: consent ? true : false }
        } else if (item === 'consent_share_informations') {
            b = { consent_share_informations: consent ? true : false }
        }
        axios
            .post(APIEndpoints.root + APIEndpoints.updateConsent, b, {
                headers: {
                    Authorization: 'Bearer ' + jwt,
                },
            })
            .then((response) => {
                if (response.status !== 200) {
                    if (response.data.message) {
                        dispatch(setAlert({ type: 'error', message: response.data.message }))
                    } else if (response.response.data.message) {
                        dispatch(setAlert({ type: 'error', message: response.data.message }))
                    } else {
                        dispatch(
                            setAlert({
                                type: 'error',
                                message: "Une erreur est survenue dans l'enregistrement de votre consentement.",
                            }),
                        )
                    }
                }
                dispatch(setAlert({ type: 'success', message: 'Vos préférences ont été enregistrées.' }))

                return true
            })
            .catch((error) => {
                if (error.response) {
                    dispatch(setAlert({ type: 'error', message: error.response.data.message }))
                } else {
                    dispatch(
                        setAlert({
                            type: 'error',
                            message: "Une erreur est survenue dans l'enregistrement de votre consentement.",
                        }),
                    )
                }
                return false
            })
    },
    sendGeolocation: async (dispatch, jwt) => {
        if (!navigator.geolocation) {
            dispatch(
                setAlert({
                    type: 'error',
                    message: "La géolocalisation n'est pas disponible sur votre appareil.",
                }),
            )
            return false
        }
        navigator.geolocation.getCurrentPosition(
            (position) => {
                dispatch(
                    updateGeolocationData({
                        lat: position.coords.latitude,
                        lon: position.coords.longitude,
                    }),
                )

                axios
                    .post(
                        APIEndpoints.root + APIEndpoints.saveUrgence,
                        {
                            action: 'GEOLOCATION',
                            lat: position.coords.latitude,
                            lon: position.coords.longitude,
                        },
                        {
                            headers: {
                                Authorization: 'Bearer ' + jwt,
                            },
                        },
                    )
                    .then((response) => {
                        if (response.status !== 200) {
                            if (response.data.message) {
                                dispatch(setAlert({ type: 'error', message: response.data.message }))
                            } else if (response.response.data.message) {
                                dispatch(setAlert({ type: 'error', message: response.data.message }))
                            } else {
                                dispatch(
                                    setAlert({
                                        type: 'error',
                                        message:
                                            "Une erreur est survenue dans l'enregistrement de votre géolocalisation.",
                                    }),
                                )
                            }
                        }
                        dispatch(
                            setAlert({ type: 'success', message: 'Votre géolocalisation a été enregistrée.' }),
                        )
                        return true
                    })
                    .catch((error) => {
                        if (error.response) {
                            dispatch(setAlert({ type: 'error', message: error.response.data.message }))
                        } else {
                            dispatch(
                                setAlert({
                                    type: 'error',
                                    message: "Une erreur est survenue dans l'enregistrement de votre consentement.",
                                }),
                            )
                        }
                        return false
                    })
            },
            () => {
                dispatch(
                    setAlert({
                        type: 'error',
                        message: 'Une erreur est survenue dans la récupération de votre géolocalisation.',
                    }),
                )
                return false
            },
        )
    },
    close: async (dispatch, jwt) => {
        axios
            .post(
                APIEndpoints.root + APIEndpoints.closeUrgence,
                {},
                {
                    headers: {
                        Authorization: 'Bearer ' + jwt,
                    },
                },
            )
            .then((response) => {
                if (response.status !== 200) {
                    if (response.data.message) {
                        dispatch(setAlert({ type: 'error', message: response.data.message }))
                    } else if (response.response.data.message) {
                        dispatch(setAlert({ type: 'error', message: response.data.message }))
                    } else {
                        dispatch(
                            setAlert({
                                type: 'error',
                                message: "La clôture de l'incident n'a pas pu être enregistrée.",
                            }),
                        )
                    }
                }
                dispatch(setAlert({ type: 'success', message: "L'incident a été clôturé." }))
                dispatch(setEmerGaUrgenceStatus('CLOSED'))
                dispatch(startEmerGaUrgence(false))

                sessionStorage.removeItem('jwt')
                sessionStorage.removeItem('time')
                sessionStorage.removeItem('store')
                return true
            })
            .catch((error) => {
                if (error.response) {
                    dispatch(setAlert({ type: 'error', message: error.response.data.message }))
                } else {
                    dispatch(
                        setAlert({
                            type: 'error',
                            message: "La clôture de l'incident n'a pas pu être enregistrée.",
                        }),
                    )
                }
                return false
            })
    },
    sendVictimId: async (victimId, dispatch, jwt) => {
        axios
            .post(
                APIEndpoints.root + APIEndpoints.saveUrgence,
                {
                    action: 'AUTHENTICATE_VICTIM',
                    victimEmerGaId: victimId,
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + jwt,
                    },
                },
            )
            .then((response) => {
                if (response.status !== 200) {
                    if (response.data.message) {
                        dispatch(setAlert({ type: 'error', message: response.data.message }))
                    } else if (response.response.data.message) {
                        dispatch(setAlert({ type: 'error', message: response.data.message }))
                    } else {
                        dispatch(
                            setAlert({
                                type: 'error',
                                message: "Une erreur est survenue dans l'authentification de la victime.",
                            }),
                        )
                    }
                }
                dispatch(setAlert({ type: 'success', message: 'La victime est authentifiée.' }))
                return true
            })
            .catch((error) => {
                if (error.response) {
                    dispatch(setAlert({ type: 'error', message: error.response.data.message }))
                } else {
                    dispatch(
                        setAlert({
                            type: 'error',
                            message: "Une erreur est survenue dans l'authentification de la victime.",
                        }),
                    )
                }
                return false
            })
    },
    retrieveUrgence: async (
        url,
        dispatch,
        serverConfig,
        setReadyForDisplay,
        setUpdateConsentModal,
    ) => {
        axios
            .post(APIEndpoints.root + APIEndpoints.retrieveUrgence, {
                origin: 'WEBAPP',
                url: url,
            })
            .then((response) => {
                dispatch(
                    setAlert({
                        type: 'success',
                        message: response.data.message,
                    }),
                )
                if (response.status === 200) {
                    dispatch(setUrgenceJWT(response.data.jwt))
                    dispatch(setGestures(response.data.gestures))
                    if (response.data.competentServices) {
                        dispatch(setCompetentServices(response.data.competentServices))
                    } else if (serverConfig.isEmerGaAvailable) {
                        dispatch(setCompetentServices(serverConfig.isEmerGaAvailable))
                    } else {
                        dispatch(setCompetentServices([]))
                    }
                    dispatch(startEmerGaUrgence(true))
                    dispatch(setEmerGaUrgenceStatus('DISPLAY_GESTURES'))
                    setUpdateConsentModal(true)

                    let d = new Date()
                    sessionStorage.setItem('jwt', response.data.jwt)
                    sessionStorage.setItem('time', d.getTime() + 3600000)

                    setReadyForDisplay(true)
                    /*
                                                  if (userConsent.geolocalisation) {
                                                      urgenceFetchUtils.sendGeolocation(dispatch, response.data.jwt)
                                                  }*/
                } else {
                    if (response.data.message) {
                        dispatch(setFatalError({ active: true, message: response.data.message }))
                    } else {
                        dispatch(setFatalError({ active: true }))
                    }
                }
            })
            .catch((error) => {
                dispatch(setFatalError({ active: true }))
            })
    },
}

export { urgenceFetchUtils }
