import { useSelector } from 'react-redux'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

import { Switch } from '@mui/material'

import { setEmerGaUrgenceStatus, setUserConsent, setAlert } from '../../store'

import { urgenceFetchUtils } from '../../utils'

import { Modal } from '../../components'

const UrgenceConsent = (props) => {
  const userConsent = useSelector((Store) => Store.Webapp.userConsent)
  if (props.kind === 'initial') {
    return (
      <>
        <Modal open={props.modal}>
          <div className='flex-h'>
            <div
              className='btn-back'
              onClick={() => {
                props.dispatch(setEmerGaUrgenceStatus('AVERTISSEMENT'))
              }}
            >
              <ArrowBackIcon />
            </div>
            <h2>Veuillez accepter certaines conditions.</h2>
          </div>
          <div className='separator'></div>
          <p>Pour fonctionner convenablement, EmerGa a besoin de certaines autorisations.</p>

          <div className='separator-10'></div>

          <div className='flex-h'>
            <Switch
              onChange={(e) => {
                props.dispatch(setUserConsent({ cgu: e.target.checked }))
              }}
            />
            <b style={{ alignSelf: 'center' }}>Conditions générales d'utilisation</b>
          </div>
          <small>
            Pour utiliser EmerGa, vous devez accepter nos Conditions Générales d’Utilisation
          </small>

          <div className='separator-10'></div>

          <div className='flex-h'>
            <Switch
              onChange={(e) => {
                props.dispatch(setUserConsent({ transmissionInfosSp: e.target.checked }))
              }}
            />
            <b style={{ alignSelf: 'center' }}>
              Transmission d'informations aux services de secours
            </b>
          </div>
          <small>
            EmerGa pourra transmettre, avec votre autorisation, les informations recueillies aux
            services de secours territorialement compétents.
          </small>

          <div className='separator-10'></div>

          <div className='flex-h'>
            <Switch
              onChange={(e) => {
                props.dispatch(setUserConsent({ geolocalisation: e.target.checked }))
              }}
            />
            <b style={{ alignSelf: 'center' }}>Géolocalisation</b>
          </div>
          <small>
            Les informations de géolocalisation de votre appareil seront transmises aux services de
            secours territorialement compétents en cas de besoin urgent.
          </small>

          <div className='separator'></div>
          <button
            className='btn-next'
            type='button'
            onClick={() => {
              urgenceFetchUtils.checkLaunchable(
                userConsent,
                () => {
                  urgenceFetchUtils.launch(
                    props.dispatch,
                    userConsent.geolocalisation,
                    userConsent.transmissionInfosSp,
                    userConsent,
                    props.INTEGRAL_STORE,
                    props.userId,
                  )
                },
                () => {
                  props.dispatch(
                    setAlert({
                      type: 'error',
                      message:
                        "Veuillez accepter les Conditions générales d'utilisation avant d'utiliser EmerGa Urgence.",
                    }),
                  )
                },
              )
            }}
          >
            <span>Continuer</span>
            <ArrowForwardIcon />
          </button>
        </Modal>
      </>
    )
  } else {
    return (
      <>
        <Modal open={props.modal}>
          <div className='flex-h'>
            <div
              className='btn-back'
              onClick={() => {
                props.closeModal()
              }}
            >
              <CloseIcon />
            </div>
            {props.isFromSinapse ? (
              <h2>Veuillez accepter certaines conditions.</h2>
            ) : (
              <h2>Modifiez vos préférences</h2>
            )}
          </div>
          <div className='separator'></div>
          <h3>Autorisations</h3>
          {props.isFromSinapse ? (
            <p>
              EmerGa a besoin de certaines autorisations pour fonctionner convenablement.
              <br />
              <br />
              Vous pouvez modifier à tout moment les autorisations accordées à EmerGa.
            </p>
          ) : (
            <p>Vous pouvez modifier à tout moment les autorisations accordées à EmerGa.</p>
          )}

          <div className='separator-10'></div>

          <div className='flex-h'>
            <Switch
              checked={true}
              disabled
              onChange={(e) => {
                props.dispatch(setUserConsent({ cgu: e.target.checked }))
              }}
            />
            <b style={{ alignSelf: 'center' }}>Conditions générales d'utilisation</b>
          </div>
          <small>
            Pour utiliser EmerGa, vous devez accepter nos Conditions Générales d’Utilisation.
            <br />
            <b>
              Vous ne pouvez plus refuser les Conditions. Si vous n'y consentez plus, vous ne devez
              pas utiliser EmerGa.
            </b>
          </small>

          <div className='separator-10'></div>

          <div className='flex-h'>
            <Switch
              checked={props.userConsent.transmissionInfosSp ? true : false}
              onChange={(e) => {
                props.dispatch(setUserConsent({ transmissionInfosSp: e.target.checked }))
                urgenceFetchUtils.updateConsent(
                  'consent_share_informations',
                  e.target.checked,
                  props.dispatch,
                  props.jwt,
                  props.INTEGRAL_STORE,
                )
              }}
            />
            <b style={{ alignSelf: 'center' }}>
              Transmission d'informations aux services de secours
            </b>
          </div>
          <small>
            EmerGa pourra transmettre, avec votre autorisation, les informations recueillies aux
            services de secours territorialement compétents.
          </small>

          <div className='separator-10'></div>

          <div className='flex-h'>
            <Switch
              checked={props.userConsent.geolocalisation ? true : false}
              onChange={(e) => {
                props.dispatch(setUserConsent({ geolocalisation: e.target.checked }))
                urgenceFetchUtils.updateConsent(
                  'consent_geolocation',
                  e.target.checked,
                  props.dispatch,
                  props.jwt,
                  props.INTEGRAL_STORE,
                )

                if (e.target.checked) {
                  urgenceFetchUtils.sendGeolocation(props.dispatch, props.jwt)
                }
              }}
            />
            <b style={{ alignSelf: 'center' }}>Géolocalisation</b>
          </div>
          <small>
            Les informations de géolocalisation de votre appareil seront transmises aux services de
            secours territorialement compétents en cas de besoin urgent.
          </small>
        </Modal>
      </>
    )
  }
}

export { UrgenceConsent }
