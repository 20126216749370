import { createSlice } from '@reduxjs/toolkit'

export const WebappReducer = createSlice({
  name: 'WebappReducer',
  initialState: {
    isRunning: false,
    isMobile: false,
    serverConfig: {},
    alerts: {
      type: null,
      message: null,
    },
    urgenceMode: false,
    isEmerGaUrgenceRunning: false,
    EmerGaUrgenceStatus: null,
    userConsent: {
      cgu: false,
      transmissionInfosSp: false,
      geolocalisation: false,
    },
    urgenceJWT: null,
    urgenceData: {
      currentQuestion: {},
      gestures: [],
      questionNumber: 0,
      competentServices: [],
    },
    fatalError: {
      active: false,
      message: 'Une erreur est survenue et empêche notre algorithme de fonctionner correctement.',
    },
    geolocationData: {},
    emergencyCallNeeded: false,
    sinapseInstruction: {
      active: false,
      data: {
        author: {
          publicName: '',
        },
        content: '',
      },
    },
  },
  reducers: {
    run: (state) => {
      state.isRunning = true
    },
    setMobile: (state, action) => {
      state.isMobile = action.payload
    },
    setServerConfig: (state, action) => {
      state.serverConfig = action.payload
    },
    setAlert: (state, action) => {
      let AlertColor = 'emergaRed'
      let AlertFilled = 'outlined'
      let AlertKind = 'error'
      let alertType = action.payload.type
      let sx = {}

      if (alertType) {
        if (alertType === 'error') {
          AlertColor = 'emergaRed'
          AlertFilled = 'filled'
          AlertKind = 'error'
          sx = action.payload.sx ? { ...action.payload.sx } : {}
        } else if (alertType === 'success') {
          AlertColor = 'emergaGreen'
          AlertFilled = 'standard'
          AlertKind = 'success'
          sx = action.payload.sx ? { ...action.payload.sx } : {}
        } else if (alertType === 'info') {
          AlertColor = 'emergaSoftBlue'
          AlertFilled = 'standard'
          AlertKind = 'info'
          sx = action.payload.sx ? { ...action.payload.sx } : {}
        } else {
          AlertColor = 'primary'
          AlertFilled = 'standard'
          AlertKind = 'info'
          sx = action.payload.sx ? { ...action.payload.sx } : {}
        }
      }

      state.alerts = {
        kind: AlertKind,
        color: AlertColor,
        variant: AlertFilled,
        message: action.payload.message,
        id: action.payload.id ?? state.alerts.length,
        sx,
      }
    },
    removeAlert: (state) => {
      state.alerts = {}
    },
    setUrgenceMode: (state, action) => {
      state.urgenceMode = action.payload
    },
    startEmerGaUrgence: (state, action) => {
      state.isEmerGaUrgenceRunning = action.payload
    },
    setEmerGaUrgenceStatus: (state, action) => {
      state.EmerGaUrgenceStatus = action.payload
    },
    setUserConsent: (state, action) => {
      state.userConsent = {
        ...state.userConsent,
        ...action.payload,
      }
      console.log(state.userConsent)
    },
    setUrgenceJWT: (state, action) => {
      state.urgenceJWT = action.payload
    },
    setUrgenceQuestionData: (state, action) => {
      state.urgenceData.currentQuestion = {
        ...action.payload,
      }
    },
    setFatalError: (state, action) => {
      state.fatalError = {
        ...action.payload,
      }
    },
    incrementQuestionNumber: (state) => {
      state.urgenceData.questionNumber += 1
    },
    resetQuestionNumber: (state) => {
      state.urgenceData.questionNumber = 0
    },
    setGestures: (state, action) => {
      state.urgenceData.gestures = action.payload
    },
    setCompetentServices: (state, action) => {
      state.urgenceData.competentServices = action.payload
    },
    updateGeolocationData: (state, action) => {
      state.geolocationData = {
        ...state.geolocationData,
        ...action.payload,
      }
    },
    setCallNeeded: (state, action) => {
      state.emergencyCallNeeded = action.payload
    },
    retrieveLastState: (state, action) => {
      let defaultStore = action.payload
      state.isRunning = defaultStore.isRunning
      state.isMobile = defaultStore.isMobile
      state.serverConfig = defaultStore.serverConfig
      state.alerts = defaultStore.alerts
      state.urgenceMode = defaultStore.urgenceMode
      state.isEmerGaUrgenceRunning = defaultStore.isEmerGaUrgenceRunning
      state.EmerGaUrgenceStatus = defaultStore.EmerGaUrgenceStatus
      state.userConsent = defaultStore.userConsent
      state.urgenceJWT = defaultStore.urgenceJWT
      state.urgenceData = defaultStore.urgenceData
      state.fatalError = defaultStore.fatalError
      state.geolocationData = defaultStore.geolocationData
      state.emergencyCallNeeded = defaultStore.emergencyCallNeeded
      /*state = {
                isRunning: defaultStore.isRunning,
                isMobile: defaultStore.isMobile,
                serverConfig: {
                    ...defaultStore.serverConfig
                },
                alerts: { ...defaultStore.alerts },
                urgenceMode: defaultStore.urgenceMode,
                isEmerGaUrgenceRunning: defaultStore.isEmerGaUrgenceRunning,
                EmerGaUrgenceStatus: defaultStore.EmerGaUrgenceStatus,
                userConsent: { ...defaultStore.userConsent },
                urgenceJWT: defaultStore.urgenceJWT,
                urgenceData: {
                    ...defaultStore.urgenceData
                },
                fatalError: { ...defaultStore.fatalError },
                geolocationData: { ...defaultStore.geolocationData },
                emergencyCallNeeded: defaultStore.emergencyCallNeeded,
            }*/
    },
    setSinapseInstruction: (state, action) => {
      if (typeof action.payload == 'object') {
        state.sinapseInstruction = {
          active: true,
          data: action.payload,
        }
      } else {
        state.sinapseInstruction = {
          active: false,
          data: {
            author: {
              publicName: '',
            },
            content: '',
          },
        }
      }
    },
  },
})

export const {
  run,
  setMobile,
  setServerConfig,
  setAlert,
  removeAlert,
  setUrgenceMode,
  startEmerGaUrgence,
  setEmerGaUrgenceStatus,
  setUserConsent,
  setUrgenceJWT,
  setUrgenceQuestionData,
  setFatalError,
  incrementQuestionNumber,
  resetQuestionNumber,
  setGestures,
  setCompetentServices,
  updateGeolocationData,
  setCallNeeded,
  retrieveLastState,
  setSinapseInstruction,
} = WebappReducer.actions

export default WebappReducer.reducer
