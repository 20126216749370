const PageLinks = {
  Accueil: '/',
  Onboarding: '/hello',
  Urgence: '/urgence/',
  UrgenceCustom: '/u/:mtId',
  ClosedUrgence: '/urgence/closed',
  UrgenceEmbed: '/urgence/embed/:userId',
  Parametres: '/parameters/',

  MentionsLegales: '/mentions-legales',
  PolConf: '/politique-de-confidentialite',
  CGU: '/conditions-generales-d-utilisation',
}

export { PageLinks }
