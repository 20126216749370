import { useEffect, useRef, useState, useCallback } from 'react'
import { Metadata } from '../utils'
import Slider from 'react-slick'
import { setUrgenceMode, setEmerGaUrgenceStatus } from '../store'
import appSlide from '../assets/visuel-app.png'
import interopSlide from '../assets/visuel-sinapse.png'
import teamSlide from '../assets/visuel-team.png'
import { useDispatch } from 'react-redux'

import Skeleton from '@mui/material/Skeleton'
import EmergencyShareIcon from '@mui/icons-material/EmergencyShare'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

const slideData = [
  {
    imageSrc: appSlide,
    title: 'Bienvenue sur la plateforme EmerGa 🙌',
    description:
      "EmerGa vous aide à porter secours en cas d'urgence ! Utilisez la plateforme EmerGa pour être accompagné(e) face à tout incident nécessitant une prise en charge d'urgence.",
  },
  {
    imageSrc: teamSlide,
    title: 'Vous pouvez sauver une vie !',
    description:
      "EmerGa vous indique quels gestes de secours vous permettent d'agir. En quelques instants, nous vous aidons à sauver une victime en détresse.",
  },
  {
    imageSrc: interopSlide,
    title: 'Échangez avec les services de secours !',
    description:
      "EmerGa vous permet de transmettre des informations en direct aux secours publics. Les services de secours sont informés en temps réel de l'urgence à laquelle vous êtes confronté(e).",
  },
]

const Onboarding = () => {
  const [activeSlide, setActiveSlide] = useState(0)
  const [loadedImages, setLoadedImages] = useState([false, false, false])

  const sliderRef = useRef()
  const dispatch = useDispatch()

  useEffect(() => {
    const imageLoadStatus = new Array(slideData.length).fill(false)

    const loadImages = () => {
      imageLoadStatus.forEach((loaded, index) => {
        const image = new Image()
        image.src = slideData[index].imageSrc
        image.onload = () => {
          imageLoadStatus[index] = true
          if (imageLoadStatus.every((loaded) => loaded)) {
            setLoadedImages(imageLoadStatus)
          }
        }
      })
    }

    loadImages()
  }, [])

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    dotsClass: 'slick-dots',
    beforeChange: (current, next) => setActiveSlide(next),
  }

  return (
    <>
      <div className='mainRow'>
        <div>
          <Slider ref={sliderRef} className='onboarding-carousel-container' {...settings}>
            {slideData.map((slide, index) => (
              <div className={`slide-${index + 1}`} key={index}>
                <div className='onboarding-slide-illustration'>
                  {!loadedImages[index] ? (
                    <Skeleton variant='rectangular' width='100%' height={200} animation='wave' />
                  ) : null}
                  <img
                    className={`slide-img slide-img-${index + 1}`}
                    src={slide.imageSrc}
                    alt='App illustration'
                    loading='lazy'
                    style={{ display: loadedImages[index] ? 'block' : 'none' }}
                  />
                </div>
                <h3>{slide.title}</h3>
                <p>{slide.description}</p>
              </div>
            ))}
          </Slider>
        </div>
        <div className='onboarding-controls-container'>
          <div className='onboarding-buttons-container'>
            <button
              onClick={() => {
                dispatch(setUrgenceMode(true))
                dispatch(setEmerGaUrgenceStatus(null))
              }}
              className='onboarding-button launch-button'
              type='button'
            >
              <EmergencyShareIcon sx={{ fontSize: 22 }} />
              <span>Lancer le Mode Urgence</span>
            </button>
            {activeSlide !== 2 && (
              <button
                onClick={() => sliderRef.current.slickNext()}
                className='onboarding-button next-button-outlined'
                type='button'
              >
                <span>Continuer</span>
                <ChevronRightIcon sx={{ fontSize: 22 }} />
              </button>
            )}
          </div>
          <div className='onboarding-footer-text'>
            <p className='app-recommend-account'>Vous avez un compte EmerGa ?</p>
            <a
              className='app-recommend-text'
              href={Metadata.appDownloadUrl}
              target='_blank'
              rel='noreferrer'
            >
              <strong>Utiliser l'application</strong>
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

const OnboardingFail = () => {
  return (
    <div className='onboarding-failure-container'>
      <span className='onboarding-failure'>
        L'application EmerGa ne peut être utilisée que sur un téléphone mobile.
      </span>
      <a className='temp-button' href={Metadata.appDetailsUrl} target='_blank' rel='noreferrer'>
        En savoir plus
      </a>
    </div>
  )
}

export { Onboarding, OnboardingFail }
