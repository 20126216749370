import EmergencyShareIcon from '@mui/icons-material/EmergencyShare'

import { startEmerGaUrgence, setEmerGaUrgenceStatus } from '../../store'

const UrgenceOnboarding = (props) => {
  return (
    <div className='urgence-mainRow'>
      <h3 style={props.isEmbed ? { color: '#000000', fontSize: '26px' } : {}}>
        Bienvenue dans le mode Urgence. 🚨
      </h3>
      <p>
        EmerGa Urgence peut être utilisé à <b>tout moment</b>, si vous êtes{' '}
        <b>témoin d’un incident urgent</b>.
      </p>
      <p>
        En cliquant sur “Démarrer”, notre algorithme va vous aider à porter secours et à transmettre
        les informations essentielles aux services de secours.
      </p>

      <button
        onClick={() => {
          props.dispatch(startEmerGaUrgence(true))
          props.dispatch(setEmerGaUrgenceStatus('AVERTISSEMENT'))
        }}
        className='onboarding-button launch-button'
        type='button'
      >
        <EmergencyShareIcon sx={{ fontSize: 22 }} />
        <span>Démarrer</span>
      </button>
    </div>
  )
}

export { UrgenceOnboarding }
