const APIEndpoints = {
  root: process.env.REACT_APP_API_URL,
  getConfig: '/api/config?origin=webapp',
  launchUrgence: '/api/urgence/launchUrgence',
  sendAnswer: '/api/urgence/algorithm/sendAnswer',
  saveUrgence: '/api/urgence/saveUrgence',
  updateConsent: '/api/webapp/updateConsent',
  closeUrgence: '/api/urgence/closeUrgence',
  retrieveUrgence: '/api/urgence/retrieveUrgence',
}

const WSEndpoints = {
  root: process.env.REACT_APP_WS_URL,
}

export { APIEndpoints, WSEndpoints }
