import { createTheme } from '@mui/material/styles'

const CustomTheme = createTheme({
  palette: {
    primary: {
      main: '#161667',
    },
    red: {
      main: '#b12727',
      light: '#b12727',
    },
    emergaRed: {
      main: '#e89b9b',
      contrastText: '#ffffff',
      light: '#e89b9b',
    },
    white: {
      main: '#f4f3f8',
      light: '#f4f3f8',
    },
    emergaSoftBlue: {
      main: '#5151c4',
      light: '#5151c4',
    },
    emergaGreen: {
      main: '#257039',
      light: '#257039',
    },
    emergaDarkGreyBlue: {
      main: '#1E2947',
      light: '#444B63',
    },
  },
  typography: {
    fontFamily: [
      'Rubik',
      'Verdana',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
})

export { CustomTheme }
