import React, { useEffect, useState } from 'react'

import { motion, AnimatePresence } from 'framer-motion'

import { Skeleton } from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

import Slider from 'react-slick'

import { APIEndpoints, urgenceFetchUtils } from '../../utils'

const UrgenceQuestions = React.forwardRef(function UrgenceQuestions(props, ref) {
  const [alreadySeenPopup, changePopperStatus] = useState(null)

  useEffect(() => {
    /*if (props.changePopperStatus) {
                if (typeof props.alreadySeenPopup === 'boolean') {
                    console.log(props.alreadySeenPopup)
                    if (props.alreadySeenPopup === false) {
                        props.changePopperStatus(true)
                        props.setAlreadySeenPopup(true)
                        setTimeout(() => {
                            props.changePopperStatus(false)
                        }, 7000)
                    }
                }
            }*/
  }, [/*props.alreadySeenPopup*/ alreadySeenPopup])

  useEffect(() => {
    if (props.INTEGRAL_STORE) {
      if (props.lastPopupStatus !== props.INTEGRAL_STORE.EmerGaUrgenceStatus) {
        props.setLastPopupStatus(props.INTEGRAL_STORE.EmerGaUrgenceStatus)
        //props.setAlreadySeenPopup(false)
        props.changePopperStatus(true)
        changePopperStatus(true)
        setTimeout(() => {
          props.changePopperStatus(false)
        }, 7000)
      }
    }
  }, [props.INTEGRAL_STORE])

  if (props.pending) {
    return (
      <>
        <div ref={ref}>
          <AnimatePresence exitBeforeEnter>
            <div className='main-home-section-container'>
              <p className='light-text'>
                <Skeleton variant='rectangular' width='100%' height={30} animation='wave' />
              </p>
              <motion.div
                className='question-action-container'
                initial={{ opacity: 0, x: 500 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -500 }}
                transition={{ duration: 0.3 }}
              >
                <>
                  <div className='question-action-header'>
                    <Skeleton variant='rectangular' width={200} height={30} animation='wave' />
                    <p className='question-count'>
                      <Skeleton variant='rectangular' width={30} height={20} animation='wave' />
                    </p>
                  </div>
                  <div className='question-action-main'>
                    <p className='question-text'>
                      <Skeleton variant='rectangular' width='100%' height={70} animation='wave' />
                    </p>
                    <Skeleton variant='rectangular' width='100%' height={48} animation='wave' />
                    <br />
                    <Skeleton variant='rectangular' width='100%' height={48} animation='wave' />
                    <br />
                    <Skeleton variant='rectangular' width='100%' height={48} animation='wave' />
                  </div>
                </>
              </motion.div>
            </div>
          </AnimatePresence>
        </div>
      </>
    )
  }
  if (props.questioning) {
    console.log(props.urgenceData)
    return (
      <>
        <div ref={ref}>
          <AnimatePresence exitBeforeEnter>
            <div className='main-home-section-container'>
              {/*<p className="light-text">
                                Pour commencer, nous allons vous poser quelques questions.<br />
                                Gardez votre calme, <b>nous allons vous aider</b>.
                            </p>*/}
              <motion.div
                className='question-action-container'
                initial={{ opacity: 0, x: 500 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -500 }}
                transition={{ duration: 0.3 }}
              >
                <>
                  <div className='question-action-header'>
                    <h3 className='question-action-title'>Questions</h3>
                    <p className='question-count'>
                      <b>{props.urgenceData.questionNumber}</b>/3
                    </p>
                  </div>
                  <div className='question-action-main'>
                    <p
                      className='question-text'
                      dangerouslySetInnerHTML={{
                        __html: props.urgenceData.currentQuestion.textContent,
                      }}
                    ></p>

                    {props.urgenceData.currentQuestion.mediaUrl && (
                      <>
                        <div className='separator'></div>
                        <img
                          className='full-size'
                          src={props.urgenceData.currentQuestion.mediaUrl}
                          alt='illustration'
                        />
                        <div className='separator'></div>
                      </>
                    )}

                    {Object.entries(props.urgenceData.currentQuestion.answers).map(
                      (content, index) => {
                        return (
                          <motion.button
                            whileTap={{ scale: 0.95 }}
                            onClick={() => {
                              urgenceFetchUtils.handleAnswer(
                                props.urgenceData.currentQuestion.id,
                                content[0],
                                props.dispatch,
                                props.jwt,
                                props.urgenceData.questionNumber,
                                props.INTEGRAL_STORE,
                              )
                            }}
                            className='question-temp-button'
                            type='button'
                            key={content[0]}
                          >
                            <span>
                              {content[1].startsWith("Oui")
                                ? '✅ '+content[1]
                                : content[1].startsWith("Non")
                                ? '❌ '+content[1]
                                : content[1]}
                            </span>
                            <ArrowForwardIcon />
                          </motion.button>
                        )
                      },
                    )}

                    <motion.button
                      whileTap={{ scale: 0.95 }}
                      className='question-temp-button no-border'
                      type='button'
                    >
                      <span>Je ne peux pas répondre</span>
                      <ArrowForwardIcon />
                    </motion.button>
                  </div>
                </>
              </motion.div>
            </div>
          </AnimatePresence>
        </div>
      </>
    )
  }
  if (props.advancedBilan) {
    return (
      <>
        <div ref={ref}>
          <AnimatePresence exitBeforeEnter>
            <div className='main-home-section-container'>
              <motion.div
                className='question-action-container'
                initial={{ opacity: 0, x: 500 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -500 }}
                transition={{ duration: 0.3 }}
              >
                <>
                  <div className='question-action-header'>
                    <h3 className='question-action-title'>Questions complémentaires</h3>
                  </div>
                  <div className='question-action-main'>
                    <p
                      className='question-text'
                      dangerouslySetInnerHTML={{
                        __html: props.urgenceData.currentQuestion.textContent,
                      }}
                    ></p>
                    {props.urgenceData.currentQuestion.mediaUrl && (
                      <>
                        <div className='separator'></div>
                        <img
                          className='full-size'
                          src={props.urgenceData.currentQuestion.mediaUrl}
                          alt='illustration'
                        />
                        <div className='separator'></div>
                      </>
                    )}
                    {Object.entries(props.urgenceData.currentQuestion.answers).map(
                      (content, index) => {
                        return (
                          <motion.button
                            whileTap={{ scale: 0.95 }}
                            onClick={() => {
                              urgenceFetchUtils.handleAnswer(
                                props.urgenceData.currentQuestion.id,
                                content[0],
                                props.dispatch,
                                props.jwt,
                                props.urgenceData.questionNumber,
                                props.serverConfig,
                                props.INTEGRAL_STORE,
                              )
                            }}
                            className='question-temp-button'
                            type='button'
                            key={content[0]}
                          >
                            <span>
                              {content[1].startsWith("Oui")
                                ? '✅ '+content[1]
                                : content[1].startsWith("Non")
                                ? '❌ '+content[1]
                                : content[1]}
                            </span>
                            <ArrowForwardIcon />
                          </motion.button>
                        )
                      },
                    )}

                    <motion.button
                      whileTap={{ scale: 0.95 }}
                      className='question-temp-button no-border'
                      type='button'
                    >
                      <span>Je ne peux pas répondre</span>
                      <ArrowForwardIcon />
                    </motion.button>
                  </div>
                </>
              </motion.div>
            </div>
          </AnimatePresence>
        </div>
      </>
    )
  }
  if (props.displayGestures) {
    return (
      <>
        <div ref={ref}>
          <AnimatePresence exitBeforeEnter>
            <div className='main-home-section-container'>
              <Slider
                ref={props.sliderRef}
                {...{
                  dots: true,
                  infinite: false,
                  speed: 500,
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  centerMode: false,
                  dotsClass: 'slick-dots',
                  draggable: false,
                  swipe: false,
                }}
              >
                {props.urgenceData.gestures.gestures.map((gesture, index) => {
                  return (
                    <div className='question-action-container' key={index}>
                      <div className='question-action-header'>
                        <h3 className='question-action-title'>Geste de secours</h3>
                      </div>
                      <div className='separator'></div>
                      <div className='question-action-main'>
                        <p
                          className='question-text'
                          dangerouslySetInnerHTML={{ __html: gesture.html }}
                        ></p>
                        <div className='separator'></div>
                        {gesture.media && (
                          <>
                            <img className='full-size' src={gesture.media} alt='illustration' />
                            <div className='separator'></div>
                          </>
                        )}
                        <motion.button
                          whileTap={{ scale: 0.95 }}
                          onClick={() => {
                            urgenceFetchUtils
                              .handleGesture(
                                index,
                                'ACCOMPLISHED',
                                props.dispatch,
                                props.jwt,
                                props.INTEGRAL_STORE,
                              )
                              .then(() => {
                                props.sliderRef.current.slickNext()
                              })
                          }}
                          className='question-temp-button full-size'
                          type='button'
                        >
                          <span>Suivant</span>
                          <ArrowForwardIcon />
                        </motion.button>
                        <motion.button
                          whileTap={{ scale: 0.95 }}
                          onClick={() => {
                            urgenceFetchUtils
                              .handleGesture(
                                index,
                                'SKIPPED',
                                props.dispatch,
                                props.jwt,
                                props.INTEGRAL_STORE,
                              )
                              .then(() => {
                                props.sliderRef.current.slickNext()
                              })
                          }}
                          className='question-temp-button no-border'
                          type='button'
                        >
                          <span>Je ne peux pas pratiquer ce geste</span>
                          <ArrowForwardIcon />
                        </motion.button>
                      </div>
                    </div>
                  )
                })}
                {props.urgenceData.gestures.surveillance &&
                  props.urgenceData.gestures.surveillance.map((gesture, index) => {
                    return (
                      <div
                        className='question-action-container'
                        key={props.urgenceData.gestures.gestures.length + index}
                      >
                        <div className='question-action-header'>
                          <h3 className='question-action-title'>Surveillance</h3>
                        </div>
                        <div className='separator'></div>
                        <div className='question-action-main'>
                          <p
                            className='question-text'
                            dangerouslySetInnerHTML={{ __html: gesture.html }}
                          ></p>
                          <div className='separator'></div>
                          {gesture.media && (
                            <>
                              <img className='full-size' src={gesture.media} alt='illustration' />
                              <div className='separator'></div>
                            </>
                          )}
                          <motion.button
                            whileTap={{ scale: 0.95 }}
                            onClick={() => {
                              urgenceFetchUtils
                                .handleGesture(
                                  'SURVEILLANCE',
                                  'SURVEILLANCE',
                                  props.dispatch,
                                  props.jwt,
                                  props.INTEGRAL_STORE,
                                )
                                .then(() => {
                                  props.setCloseModal(true)
                                })
                            }}
                            className='question-temp-button full-size'
                            type='button'
                          >
                            <span>Clôturer l'incident</span>
                            <ArrowForwardIcon />
                          </motion.button>
                          <motion.button
                            whileTap={{ scale: 0.95 }}
                            onClick={() => {
                              props.setCallModal(true)
                            }}
                            className='question-temp-button no-border'
                            type='button'
                          >
                            <span>Je contacte les secours</span>
                            <ArrowForwardIcon />
                          </motion.button>
                        </div>
                      </div>
                    )
                  })}
              </Slider>
            </div>
          </AnimatePresence>
        </div>
      </>
    )
  }
})

export { UrgenceQuestions }
